import { Link } from "gatsby"
import React, { useState, useEffect } from "react"

import { fetchProducts } from "../services/google.service"

import Logo from "../icons/logo"
import Loader from "../components/loader"
import ProductRow from "../components/product-row"

import styles from "../styles/print.module.css"

const About: React.FC = () => {

    // State

    const [products, setProducts] = useState(null)

    // Effects

    useEffect(() => {
        fetchData()
    }, [])

    useEffect(() => {
        setTimeout(() => {
            if (products && typeof window !== 'undefined') window.print()
        }, 1000)
    }, [products])


    // Network 

    const fetchData = async () => {
        const data = await fetchProducts()
        setProducts(data)
    }

    // Rendering
    
    const renderContent = () => {
        if (!products) return <Loader/>

        return products.map(p => 
            <ProductRow 
                key={p.number}
                img={p.imgUrl} 
                title={`${p.number} ${p.titleFr}`}
                upc={p.upc} 
                format={p.format}/>
        )
    }

    return (
        <div className={styles.container}>
            <div className={styles.titles}>
                <Link to="/"><Logo dark/></Link>
                <h1>Liste de produits </h1>
                <h2>{new Date().toLocaleDateString('fr')}</h2>
            </div>
            {renderContent()}
        </div>
    )
}

export default About