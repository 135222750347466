import React from "react"

import styles from "../styles/product-row.module.css"

interface Props {
    img: string
    title: string
    upc: string
    format: string
}

const ProductRow = ({ img, title, upc, format }: Props) => (
    <div className={styles.row}>
        <div className={styles.imgHolder}>
            <img src={img}/>
        </div>
        <p>
            <strong>{title}</strong><br/>
            {upc}<br/>
            {format}
        </p>
    </div>
)

export default ProductRow
